<template>
	<CFooter :fixed="false">
		<div>
			<!-- <a href="https://savvycomsoftware.com/" target="_blank">SAVVYCOM</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} creativeLabs.</span> -->
		</div>
		<div class="mfs-auto">
			<span class="mr-1" target="_blank">Powered by</span>
			<a href="https://dashboard.3dvisualization.info/">3dvisualization</a>
		</div>
	</CFooter>
</template>

<script>
export default {
	name: "TheFooter",
}
</script>
